import { mapActions, mapGetters } from "vuex";
import qs from "qs";

export default {
  data() {
    return {
      loadingNotification: false,
      loadingNotificationAction: false,
      loadingNotificationTargets: false,
      loadingNotificationStore: false,
      loadingNotificationMetrics: false,
      Notificacoes: [],
      Tipos: [],
      Grupos: [],
      Metricas: [],
    };
  },
  computed: {
    ...mapGetters([
      "userId",
      "notificacoes",
      "notificationTargetsGroups",
      "notificationTypes",
      "notificacoesAdmin",
      "notificacoesAdminPage",
      "notificacoesAdminTotal",
    ]),
  },
  methods: {
    ...mapActions([
      "setNotifications",
      "setNotificationTipos",
      "setNotificationGrupos",
      "setNotificationsAdmin",
      "setNotificationsAdminPage",
      "setNotificationsAdminTotal",
    ]),
    async getNotifications() {
      try {
        this.loadingNotification = true;

        let res = (
          await this.$http.get(
            url + "/v2/usuarios/" + this.userId + "/notificacoes"
          )
        ).data.data;

        this.Notificacoes = res;
        await this.setNotifications(res);
      } catch (err) {
        this.loadingNotification = false;
      }
    },

    async setNotification(params) {
      try {
        this.loadingNotificationStore = true;

        let res = await this.$http.post(url + "/v2/admin/notificacoes", {
          notifications: [params],
        });

        this.loadingNotificationStore = false;

        this.$toasted.success("Criado com sucesso!", {
          duration: 3000,
          position: "top-center",
        });

        return res.data.data;
      } catch (err) {
        this.loadingNotificationStore = false;
        this.$toasted.error("Erro ao salvar Notificacao", {
          duration: 3000,
          position: "top-center",
        });
      }
    },

    async updateNotification(id, ev) {
      try {
        this.loadingNotificationStore = true;

        let params = JSON.parse(JSON.stringify(ev));
        if (params.id_notification) delete params.id_notification;

        let res = await this.$http.patch(url + "/v2/admin/notificacoes/" + id, {
          notification: params,
        });

        this.loadingNotificationStore = false;

        this.$toasted.success("Atualizado com sucesso!", {
          duration: 3000,
          position: "top-center",
        });

        return res.data.data;
      } catch (err) {
        this.loadingNotificationStore = false;
        this.$toasted.error("Erro ao atualizar Notificacao", {
          duration: 3000,
          position: "top-center",
        });
      }
    },

    async softDeleteNotification(id) {
      try {
        this.loadingNotificationStore = true;

        let res = await this.$http.delete(url + "/v2/admin/notificacoes/" + id);

        this.loadingNotificationStore = false;

        this.$toasted.success("Excluido com sucesso!", {
          duration: 3000,
          position: "top-center",
        });

        return res.data.data;
      } catch (err) {
        this.loadingNotificationStore = false;
        this.$toasted.error("Erro ao excluir a Notificacao", {
          duration: 3000,
          position: "top-center",
        });
      }
    },

    async setActions(params) {
      try {
        this.loadingNotificationAction = true;

        const fields = [
          "id_notification",
          "description",
          "area",
          "status_action",
        ];
        let aux = {};

        for (let el of fields) {
          if (!params[el]) throw new Error("Params error");
          aux[el] = params[el];
        }

        let res = await this.$http.post(
          url + "/v2/usuarios/" + this.userId + "/notificacoes/analytics",
          {
            action: aux,
          }
        );
        return res.data.data;
      } catch (err) {
        this.loadingNotificationAction = false;
        return false;
      }
    },

    async setTargetGroup(params) {
      try {
        this.loadingNotificationTargets = true;

        const fields = ["id_notification", "recipient"];
        let aux = {};

        for (let el of fields) {
          if (!params[el]) throw new Error("Params error");
          aux[el] = params[el];
        }

        let res = await this.$http.post(
          url + "/v2/admin/notificacoes/destino",
          {
            targets: aux,
          }
        );
        return res.data.data;
      } catch (err) {
        this.loadingNotificationTargets = false;
        return false;
      }
    },

    async setNotificationClosed(id) {
      await this.setActions({
        id_notification: id,
        description: "Fechou a notificação",
        area: "closed",
        status_action: "complete",
      });
    },

    async setNotificationModalClosed(id) {
      await this.setActions({
        id_notification: id,
        description: "Fechou o Modal",
        area: "closed",
        status_action: "incomplete",
      });
    },

    async setNotificationViewed(id) {
      await this.setActions({
        id_notification: id,
        description: "Abriu o Link",
        area: "link",
        status_action: "complete",
      });
    },

    async getAdminNotifications() {
      try {
        this.loadingNotification = true;

        let _url =
          url +
          "/v2/admin/notificacoes" +
          (this.notificacoesAdminPage
            ? "?page=" + this.notificacoesAdminPage
            : "");

        let res = (await this.$http.get(_url)).data.data;

        this.Notificacoes = res.lista;
        this.setNotificationsAdmin(res.lista);
        this.setNotificationsAdminTotal(res.total);

        this.loadingNotification = false;
      } catch (err) {
        this.loadingNotification = true;
      }
    },

    async getTypes() {
      try {
        let res = (await this.$http.get(url + "/v2/admin/notificacoes/tipos"))
          .data.data;
        this.Tipos = res;
        await this.setNotificationTipos(res);
      } catch (err) {}
    },

    async getTargetsGroups() {
      try {
        let res = (
          await this.$http.get(url + "/v2/admin/notificacoes/grupos-destino")
        ).data.data;
        this.Grupos = res;
        await this.setNotificationGrupos(res);
      } catch (err) {}
    },

    async getMetrics(params) {
      try {
        this.loadingNotificationMetrics = true;
        const query = qs.stringify(params);

        let res = (
          await this.$http.get(url + "/v2/admin/notificacoes/metricas?" + query)
        ).data.data;
        this.Metricas = res;

        this.loadingNotificationMetrics = false;
      } catch (err) {
        this.loadingNotificationMetrics = false;
        this.$toasted.error("Erro ao carragar Metricas", {
          duration: 3000,
          position: "top-center",
        });
      }
    },
  },
};
